#map {
  height: 80vh;
  width: 100vw;
}

.popup-container {
  font-size: large;
  .popup-body {
    width: 100%;
    font-size: xx-large;
    display: block;
  }
}
